// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAZYjPMjTiQOxZzF7yXxejiv2-GpxvWE4Y",
  authDomain: "delve-833ef.firebaseapp.com",
  projectId: "delve-833ef",
  storageBucket: "delve-833ef.appspot.com",
  messagingSenderId: "397031881724",
  appId: "1:397031881724:web:9f4496a477fe2513d39079",
  measurementId: "G-HXFK9LJ84T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore, app };