import { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styles from '../../styles/AuthPages.module.css'
import { useRouter } from 'next/router';
import { decode, encode } from '../../utils/encrypt';
import { useCommonEnquiryMutation } from '../Store/Features/CommonFormAPI';
import { usePostApiMutation } from '../Store/Features/PostApiRequest';
import { app } from '../../firebase/config';
import {
    getAuth,
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged
} from "firebase/auth";
import Swal from 'sweetalert2';

const AuthLogin = () => {
    const [PostApiRequest] = usePostApiMutation();
    const [formAPICallBack] = useCommonEnquiryMutation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [LoadingStatus, setLoadingStatus] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isErrorMessage, setIsErrorMessage] = useState('');
    const [UserInfo, setUserInfo] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    const { push } = useRouter();

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    function authCheck(){
        const auth = getAuth(app);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                const uid = user.uid;
                user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    // Send token to your backend via HTTPS
                    getAuth(app)
                    .verifyIdToken(idToken)
                    .then((decodedToken) => {
                        const uid = decodedToken.uid;
                    })
                    .catch((error) => {
                        // Handle error
                        console.log(error)
                    });
                    // ...
                  }).catch(function(error) {
                    // Handle error
                  });
                setUserInfo(user)
                console.log(user)
                // ...
            } else {
                // User is signed out
                // ...
                setUserInfo(null)
            }
        });
    }

    useEffect(() => {
        if (localStorage.getItem('remember_data') !== null) {
            try {
                let credentials = {}
                if (process.env.ENV === 'local') {
                    credentials = JSON.parse(localStorage.getItem('remember_data'));
                } else {
                    credentials = decode(localStorage.getItem('remember_data'));
                }
                setEmail(credentials?.email);
                setPassword(credentials?.password);
            } catch (e) {
            }
        }

        authCheck();
    }, [])

    const handleSubmit = async (e) => {
        const endpoint = "/client-auth/login"
        setLoadingStatus(true)
        e.preventDefault();
        let credentials = {
            email: email,
            password: password
        }
        formAPICallBack({
            data: { data: encode(credentials) },
            url: endpoint
        }).then(async (response) => {
            if (response && response?.data) {
                if (response?.data?.code === 200) {
                    let obj = { email: email, password: password }
                    let encoded_data = encode(obj)
                    if (isChecked) {
                        localStorage.setItem('remember_data', encoded_data)
                    }
                    PostApiRequest({
                        data: { token: response?.data?.token },
                        url: '/api/user/login'
                    }).then(async (resp) => {
                        if (process.env.ENV === 'local') {
                            if (isChecked) {
                                localStorage.setItem('remember_data', JSON.stringify(encoded_data))
                            }
                            localStorage.setItem('auth_data', JSON.stringify(resp?.data?.data))
                            localStorage.setItem('auth_user_token', response?.data?.token)
                        } else {
                            if (isChecked) {
                                localStorage.setItem('remember_data', encoded_data)
                            }
                            localStorage.setItem('auth_data', encode(resp?.data?.data))
                            localStorage.setItem('auth_user_token', response?.data?.token)
                        }

                        messageShow(response?.data?.message || "An error occurred")
                        if(localStorage.getItem('path_1')){
                            push(localStorage.getItem('path_1'));
                        }else{
                            push('/dashboard');
                        }
                    })
                } else {
                    messageShow(response?.data?.message || "An error occurred");
                }
            } else if (response && response?.error) {
                if(response?.error?.data?.message=='Invalid Email'){
                    Swal.fire({
                        icon: "error",
                        // title: "Oops...",
                        text: "You are not a registered user. Sign Up to get the access.",
                        footer: '<a href="/register">Click here to Register?</a>'
                    }).then(()=>{
                        setLoadingStatus(false)
                    })
                }else{
                    Swal.fire({
                        icon: "error",
                        // title: "Oops...",
                        text: "Invalid username or password",
                    }).then(()=>{
                        setLoadingStatus(false)
                    })
                }

                // messageShow(response?.error?.data?.message || response?.error?.data?.error || "An error occurred");
            } else {
                messageShow('Something Went Wrong!!')
            }
        }).catch((error) => {
        });
    };

    const messageShow = (message) => {
        setIsErrorMessage(message);
        setIsError(true);
        setLoadingStatus(false);
        setTimeout(() => {
            setIsError(false);
        }, 2000)
    }



    const GoogleLogin = async () => {
        const auth = getAuth(app);
        // signInWithPopup(auth, provider)
        // .then((result) => {
        //     // This gives you a Google Access Token. You can use it to access the Google API.
        //     const credential = GoogleAuthProvider.credentialFromResult(result);
        //     const token = credential.accessToken;
        //     // The signed-in user info.
        //     const user = result.user;
        //     // IdP data available using getAdditionalUserInfo(result)
        //     // ...
        // }).catch((error) => {
        //     // Handle Errors here.
        //     const errorCode = error.code;
        //     const errorMessage = error.message;
        //     // The email of the user's account used.
        //     const email = error.customData.email;
        //     // The AuthCredential type that was used.
        //     const credential = GoogleAuthProvider.credentialFromError(error);
        //     // ...
        // });
        try {
            // Step 1: User tries to sign in using Google.
            let result = await signInWithPopup(auth, new GoogleAuthProvider());
        } catch (error) {
            // Step 2: User's email already exists.
            if (error.code === "auth/account-exists-with-different-credential") {
                // The pending Google credential.
                let pendingCred = error.credential;

                // Step 3: Save the pending credential in temporary storage,

                // Step 4: Let the user know that they already have an account
                // but with a different provider, and let them choose another
                // sign-in method.
            }
        }
    }

    return (
        <>
            <div className=" flex flex-col min-h-full justify-center items-center  lg:py-10 py-6 bg-gray-50">
                <div className="">
                    <Link href="/">
                        <Image
                            className="mx-auto h-10 w-auto"
                            src={process.env.IMG_CDN+'png/delve-logo.png'}
                            alt="DelveInsight"
                            width={100}
                            height={100}
                        />
                    </Link>
                    <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                </div>

                <div className="w-full lg:w-96 mt-4 bg-white shadow-md p-10 rounded">
                    <form className="space-y-2" onSubmit={handleSubmit} >

                        <div className="flex flex-col">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder='name@example.com'
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            <div className="mt-2 relative">
                                <input
                                    id="password"
                                    name="password"
                                    placeholder='Password'
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                    <button type="button" htmlFor="showPassword" className="ml-1 text-gray-700"
                                        onClick={() => setShowPassword(!showPassword)}>
                                        {(showPassword) ?
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                            </svg>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-3">
                            <div className="w-full flex justify-start items-start mb-3 mt-2">
                                <div className="h-5">
                                    <input id="remember"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className="w-3 h-3 border  border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                    />
                                </div>
                                <label htmlFor="remember" className="ms-2 text-xs mt-1 text-gray-900 dark:text-gray-300">Remember me</label>
                            </div>
                            <div className="w-full flex justify-end text-right items-end mb-3 mt-2">
                                <Link href="/forgot-password" className="text-[#1b5df3] text-xs font-semibold" >Forgot Password?</Link>
                            </div>
                        </div>
                        {(isError) &&
                            <div className="flex justify-center text-center mt-1">
                                <span className='text-[#1b5df3] text-sm font-semibold' dangerouslySetInnerHTML={{ __html: `${isErrorMessage}` }}></span>
                            </div>
                        }
                        <div className='flex w-full justify-center items-center'>
                            {(LoadingStatus) ?
                                <button
                                    type="submit"
                                    disabled
                                    className="flex w-full justify-center items-center rounded-md bg-[#1b5df3] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:[#1b2df3] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1b5df3]"
                                >
                                    Sign in {' '}
                                    <span className={styles.loader}></span>
                                </button>
                                :
                                <button
                                    type="submit"
                                    className="flex w-full justify-center items-center rounded-md bg-[#1b5df3] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:[#1b2df3] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1b5df3]"
                                >
                                    Sign in {' '}
                                    <span className="flex items-center text-sm pl-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3.0} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </button>
                            }
                        </div>
                    </form>

                    <div className="flex flex-col items-center">
                        {/* <p className='my-2'> Or </p>
                        {(UserInfo) ?
                            <div className="w-full flex justify-center items-center">
                                <button
                                    onClick={() => {
                                        const auth = getAuth();
                                        signOut(auth).then(() => {
                                            authCheck()
                                        }).catch((error) => {
                                            console.log(error)
                                            // An error happened.
                                        });
                                    }}
                                    className="w-full flex justify-center items-center gap-2 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                    <Image className="h-6 w-6 rounded-full" src={UserInfo?.photoURL} height="200" width="200" alt='profile' />
                                    <span >Sign Out {UserInfo?.displayName}</span>  </button>
                            </div>
                            :
                            <div className="w-full flex justify-center items-center">
                                <button
                                    onClick={() => {
                                        GoogleLogin()
                                    }}
                                    className="w-full flex justify-center items-center gap-2 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                    <svg aria-hidden="true" className="native svg-icon iconGoogle" width="18" height="18" viewBox="0 0 18 18"><path fill="#4285F4" d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z"></path><path fill="#34A853" d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z"></path><path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z"></path><path fill="#EA4335" d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z"></path></svg>
                                    <span >Log in with Google</span>  </button>
                            </div>

                        } */}

                        <p className="mt-5 text-center text-sm text-gray-500">
                            Not a member?{' '}
                            <Link href="/register" className="font-semibold leading-6 text-[#1b5df3] hover:text-[#1b2df3]">
                                Sign up for free
                            </Link>
                        </p>
                    </div>

                </div>
            </div>
        </>

    );
};

export default AuthLogin;
